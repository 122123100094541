@font-face {
  font-family: 'Runic';
  src: url(/public/fonts/runic/ComicRunes-njeJ.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Runic2';
  src: url(/public/fonts/runic/DalekPinpointBold-lr3e.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Runic3';
  src: url(/public/fonts/runic/NorseBold-2Kge.otf) format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Spartan';
  src: url(/public/fonts/spartan/Spartan_Black_900.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Spartan';
  src: url(/public/fonts/spartan/Spartan_ExtraBold_800.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Spartan';
  src: url(/public/fonts/spartan/Spartan_Bold_700.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Spartan';
  src: url(/public/fonts/spartan/Spartan_SemiBold_600.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Spartan';
  src: url(/public/fonts/spartan/Spartan_Medium_500.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Spartan';
  src: url(/public/fonts/spartan/Spartan_Regular_400.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Spartan';
  src: url(/public/fonts/spartan/Spartan_Light_300.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Spartan';
  src: url(/public/fonts/spartan/Spartan_ExtraLight_250.ttf) format('truetype');
  font-weight: 250;
  font-style: normal;
}
